module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-R6T7ZMDLP2","AW-1061344408"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"PunchIn Biometric Access","short_name":"PunchIn","start_url":"/","background_color":"#ffffff","theme_color":"#2b6cb0","display":"standalone","icon":"src/assets/images/icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"cd251ab557a5fd51e6ec4cdee54dd491"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
